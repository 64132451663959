import { useEffect } from "react"
import { useProfileStore } from "@/store/profileStore"
import { useSubscriptionStore, usePoliciesStore } from "@/store/subscriptionStore"
import { fetchProfile } from "@/app/api/profile/api"
import { getCurrentSubscription, getSelectablePolicies } from "@/app/api/billing/api"

import Top from "@/components/Layout/Top/Top"
import Main from "@/components/Layout/Main/Main"
import Left from "@/components/Layout/Left/Left"
import Snackbar from "@/components/Snackbar/Snackbar"
import SubscriptionModals from "@/components/Subscription/SubscriptionModals"
import AlertBanner from "@/components/AlertBanner/AlertBanner"
import { usePathname } from "next/navigation"
import { logout } from "@/app/api/logout/api"

export default function GlobalLayout({ children }) {
  const path = usePathname()
  const setProfile = useProfileStore(state => state.setProfile)
  const setPolicies = usePoliciesStore(state => state.setPolicies)
  const setSubscription = useSubscriptionStore(state => state.setSubscriptionInfo)
  const profile = useProfileStore(state => state.profile)
  const isSubscriptionLoaded = useSubscriptionStore(
    state => state.isSubscriptionLoaded,
  )
  const setSubscriptionLoaded = useSubscriptionStore(
    state => state.setSubscriptionLoaded,
  )

  useEffect(() => {
    // 크롬 브라우저에서 발생하는 ResizeObserver 에러를 처리하기 위한 이벤트 리스너 추가
    const handleError = function (e) {
      if (
        e.message.includes(
          "ResizeObserver" ||
            e.message ===
              "ResizeObserver loop completed with undelivered notifications.",
        )
      ) {
        e.stopImmediatePropagation()
        e.preventDefault()
      }
    }

    // 에러 이벤트 리스너 추가
    window.addEventListener("error", handleError)

    // 컴포넌트 언마운트 시 이벤트 리스너 제거를 위한 클린업 함수 반환
    return () => {
      window.removeEventListener("error", handleError)
    }
  }, [])

  useEffect(() => {
    fetchProfile().then(profile => {
      if (!profile) {
        logout("/login")
        return
      }

      setProfile(profile)

      setTimeout(() => {
        getCurrentSubscription().then(subscription => {
          setSubscription(subscription)
          setSubscriptionLoaded()
        })

        getSelectablePolicies().then(policies => {
          setPolicies(policies)
        })
      }, 10)
    })
  }, [path])

  if (!profile?.workspace) {
    return <div></div>
  }

  return (
    <>
      <Left />
      <Main>
        <Top />
        <Snackbar />
        {profile && <AlertBanner />}
        <div className="mt-[48px] px-4 py-8 md:px-10">{children}</div>
      </Main>
      {isSubscriptionLoaded && <SubscriptionModals />}
    </>
  )
}
