import { instance } from "@/app/api/api"
import { BaseResponse } from "@/app/api/baseResponse-api-models"
import { Profile, ProfileResponse } from "@/types/profile"
import { workspaceId } from "@/app/api/config"
import { useProfileStore } from "@/store/profileStore"
import { formatBusinessRegistrationNumber, formatPhoneNumber } from "@/utils/formats"

function isNeedOnboarding(profile: Profile | null): boolean {
  return (
    !!profile &&
    (!profile.workspace?.businessRegistrationNumber ||
      !profile.workspace?.organizationName ||
      !profile.workspace?.representativeName)
  )
}

export const fetchProfile = async (): Promise<Profile> => {
  const url = `/api/v1/profiles`
  const {
    data: { body },
  } = await instance.get<BaseResponse<ProfileResponse>>(url)

  let profile = {
    account: body.account,
    workspace: body.workspaces[0]?.workspace || null,
    collaborator: body.workspaces[0]?.collaborator || null,
    certificates: body.workspaces[0]?.certificates,
    invitations: body.invitations,
    certificateNtsDataFetchRegistered:
      body.workspaces[0]?.certificateNtsDataFetchRegistered,
    certificateNtsTaxInvoiceIssuanceRegistered:
      body.workspaces[0]?.certificateNtsTaxInvoiceIssuanceRegistered,
    certificateBankDataFetchRegistered:
      body.workspaces[0]?.certificateBankDataFetchRegistered,
  }

  const profileStoreId = useProfileStore.getState().profile?.workspace?.id

  if (profileStoreId) {
    const workspace = body.workspaces.find(
      workspace => workspace.workspace?.id === profileStoreId,
    )

    if (workspace) {
      profile = {
        account: body.account,
        workspace: workspace.workspace,
        collaborator: workspace.collaborator,
        certificates: workspace.certificates,
        invitations: body.invitations,
        certificateNtsDataFetchRegistered:
          workspace.certificateNtsDataFetchRegistered,
        certificateNtsTaxInvoiceIssuanceRegistered:
          workspace.certificateNtsTaxInvoiceIssuanceRegistered,
        certificateBankDataFetchRegistered:
          workspace.certificateBankDataFetchRegistered,
      }
    }
  }

  if (!profile.workspace && !profile.invitations.length) {
    window.location.href = "/join/step1?isCreate=true"
  }

  if (profile.workspace && isNeedOnboarding(profile) && !profile.invitations.length) {
    window.location.href = "/join/step1"
  }

  if (profile.workspace?.businessRegistrationNumber)
    profile.workspace.businessRegistrationNumber = formatBusinessRegistrationNumber(
      profile.workspace.businessRegistrationNumber,
    )

  if (profile.workspace?.staff.telephone) {
    profile.workspace.staff.telephone = formatPhoneNumber(
      profile.workspace.staff.telephone,
    )
  }

  useProfileStore.getState().setWorkspaces(body.workspaces)

  return profile
}

export const deleteCertificate = async (certificateId: number) => {
  const url = `/api/v1/workspaces/${workspaceId}/certificates/${certificateId}`

  try {
    await instance.delete(url)
    return true
  } catch (error) {
    return false
  }
}
