import ALink from "./ALink"
import AGroup from "./AGroup"
import LockedLink from "./LockedLink"
import { useChannelTalk } from "@/hooks/useChannelTalk"
import { Divider } from "@nextui-org/react"
import { useProfileStore } from "@/store/profileStore"

import NavIcon1 from "@/assets/icons/nav_icon1.svg"
import NavIcon1Active from "@/assets/icons/nav_icon1_active.svg"
import NavIcon2 from "@/assets/icons/nav_icon2.svg"
import NavIcon2Active from "@/assets/icons/nav_icon2_active.svg"
import NavIcon3 from "@/assets/icons/nav_icon3.svg"
import NavIcon3Active from "@/assets/icons/nav_icon3_active.svg"
import NavIcon4 from "@/assets/icons/nav_icon4.svg"
import NavIcon4Active from "@/assets/icons/nav_icon4_active.svg"
import NavIcon5 from "@/assets/icons/nav_icon5.svg"
import NavIcon5Active from "@/assets/icons/nav_icon5_active.svg"
// import NavIcon6 from "@/assets/icons/nav_icon6.svg"
// import NavIcon6Active from "@/assets/icons/nav_icon6_active.svg"
import NavIcon7 from "@/assets/icons/nav_icon7.svg"
import NavIcon7Active from "@/assets/icons/nav_icon7_active.svg"
import NavIcon8 from "@/assets/icons/nav_icon8.svg"
import NavIcon9 from "@/assets/icons/nav_icon9.svg"
import NavIcon9Active from "@/assets/icons/nav_icon9_active.svg"

export default function NavLinks() {
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()
  const channelTalk = useChannelTalk()

  return (
    <div className="mt-4 px-6 pb-6">
      {authorizedFeatures.includes("TAX_INVOICE_ISSUE_REQUEST") && (
        <ALink
          name="간편발행"
          href="/issuance"
          icon={NavIcon4}
          activeIcon={NavIcon4Active}
        />
      )}
      {authorizedFeatures.includes("PURCHASED_TAX_INVOICE_FETCH") && (
        <ALink
          name="발행 목록"
          href="/invoices"
          icon={NavIcon1}
          activeIcon={NavIcon1Active}
        />
      )}
      {authorizedFeatures.includes("TAX_INVOICE_ISSUE_REQUEST") && (
        <ALink
          name="고객 관리"
          href="/customers"
          icon={NavIcon3}
          activeIcon={NavIcon3Active}
        />
      )}
      {authorizedFeatures.includes("ISSUANCE_REQUEST_FORM_FETCH") && (
        <ALink
          name="발행정보 요청서"
          href="/request-form"
          icon={NavIcon2}
          activeIcon={NavIcon2Active}
        />
      )}
      {(authorizedFeatures.includes("TAX_INVOICE_ISSUE_REQUEST") ||
        authorizedFeatures.includes("PURCHASED_TAX_INVOICE_FETCH") ||
        authorizedFeatures.includes("ISSUANCE_REQUEST_FORM_FETCH")) && (
        <Divider className="my-5 bg-[#EEEFF1]" />
      )}
      {authorizedFeatures.includes("RECEIVED_TAX_INVOICE_FETCH") && (
        <ALink
          name="매입 세금계산서"
          href="/received-invoices"
          icon={NavIcon5}
          activeIcon={NavIcon5Active}
        />
      )}
      {authorizedFeatures.includes("BANK_ACCOUNT_TRANSACTION_FETCH") && (
        <ALink
          name="입출금 내역 관리"
          href="/transactions"
          icon={NavIcon7}
          activeIcon={NavIcon7Active}
        />
      )}
      {authorizedFeatures.includes("PAYMENT_REQUEST_MANAGEMENT") ? (
        <AGroup
          title="지급요청 관리"
          href="request-payment"
          icon={NavIcon9}
          activeIcon={NavIcon9Active}
        >
          <ALink name="지급요청 목록" href="/request-payment/requests" />
          <ALink name="지급 양식 만들기" href="/request-payment/templates" />
          <ALink name="지급 코드관리" href="/request-payment/codes" />
        </AGroup>
      ) : (
        <ALink
          name="지급요청 목록"
          href="/request-payment/requests"
          icon={NavIcon9}
          activeIcon={NavIcon9Active}
        />
      )}

      <LockedLink
        name="API 연동"
        icon={NavIcon8}
        onClick={() => channelTalk.openSupportBot("101862")}
      />
    </div>
  )
}
