export const COLORS = {
  warning: {
    bgColor: "bg-[#FFFAF4]",
    linkColor: "text-primary",
  },
  danger: {
    bgColor: "bg-danger",
    linkColor: "text-danger",
  },
  info: {
    bgColor: "bg-[#F1F8FF]",
    linkColor: "text-[#077DFD]",
  },
}
