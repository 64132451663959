"use client"
import style from "./Top.module.scss"
import { useLayoutStore, useBreakpointStore } from "@/store/layoutStore"

import { Bars3Icon } from "@heroicons/react/24/outline"

export default function Top() {
  const toggleLeft = useLayoutStore(state => state.toggleLeft)
  const isLeftOpen = useLayoutStore(state => state.isLeftOpen)
  const isMobile = useBreakpointStore(state => state.isMobile)

  return (
    <div
      className={`${style.top} ${isLeftOpen && !isMobile ? style.leftOpened : ""}`}
    >
      <div>
        <Bars3Icon
          className="-ml-3 h-10 w-10 cursor-pointer rounded-full p-[6px] opacity-60 transition hover:bg-stone-50 hover:opacity-100"
          onClick={toggleLeft}
        />
      </div>
      <div className="ml-auto">{/* empty */}</div>
    </div>
  )
}
