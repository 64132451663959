import { useEffect, useState } from "react"
import { useAlertStore } from "@/store/alertStore"
import { useProfileStore } from "@/store/profileStore"
import { getAlertBanner } from "@/app/api/workspace/api"
import { COLORS } from "./constants"
import { redirectToCertification } from "@/utils/redirectToCertification"

import Banner from "./Banner"
import PlanModal from "@/components/Subscription/PlanModal"

type attribute = {
  daysLeft?: number
  scheduledIssueCount?: number
}

export default function AlertBanner() {
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [alertKey, setAlertKey] = useState<string | null>(null)
  const [attribute, setAttribute] = useState<attribute>({})
  const bypassKeys = useAlertStore(state => state.bypassKeys)
  const setBypassKey = useAlertStore(state => state.setBypassKey)
  const profile = useProfileStore(state => state.profile)
  const authorizedFeatures = useProfileStore.getState().getAuthorizedFeatures()

  useEffect(() => {
    if (profile) {
      fetchAlertBanner()
    }
  }, [profile])

  const fetchAlertBanner = async () => {
    const alertBanner = await getAlertBanner(bypassKeys)
    if (!alertBanner) return

    setAttribute(alertBanner.attribute)
    setAlertKey(alertBanner.name)
  }

  if (!profile || !alertKey) {
    return null
  }

  const Banners = {
    // 공동인증서 등록 필요
    NEED_CERTIFICATE_REGISTERED_WHEN_INITIAL: {
      variant: "warning",
      closeBtn: false,
      children() {
        return (
          <>
            공동인증서 등록 후 서비스를 이용할 수 있습니다.
            {authorizedFeatures.includes("CERTIFICATE_MANAGEMENT") && (
              <a className={linkClass} onClick={() => redirectToCertification()}>
                등록하기
              </a>
            )}
          </>
        )
      },
    },

    // 세금계산서용 공동인증서 등록 필요
    NEED_TAX_INVOICE_ISSUANCE_AVAILABLE_CERTIFICATE_REGISTERED: {
      variant: "warning",
      closeBtn: false,
      children() {
        return (
          <>
            세금계산서 발행이 가능한 인증서(범용, 전자세금용) 등록 후 발행 서비스를
            이용하실 수 있습니다.
            {authorizedFeatures.includes("CERTIFICATE_MANAGEMENT") && (
              <a className={linkClass} onClick={() => redirectToCertification()}>
                등록하기
              </a>
            )}
          </>
        )
      },
    },

    // 무료체험 종료안내
    PLAN_UPGRADE_WHEN_FREE_TRIAL_IS_ABOUT_TO_EXPIRE: {
      variant: "warning",
      closeBtn: true,
      children() {
        return (
          <>
            무료체험 기간 종료까지 <b>{attribute.daysLeft}일</b> 남았습니다. 플랜을
            미리 업그레이드 해보세요.
            <a className={linkClass} onClick={() => setPlanModalOpen(true)}>
              업그레이드
            </a>
          </>
        )
      },
    },

    // 예약 세금계산서 플랜 업그레이드가 필요
    PLAN_UPGRADE_WHEN_SCHEDULED_ISSUE_EXISTS: {
      variant: "warning",
      closeBtn: true,
      children() {
        return (
          <>
            예약 세금계산서 <b>{attribute.scheduledIssueCount}건</b> 발행을 위해 플랜
            업그레이드가 필요합니다.
            <a className={linkClass} onClick={() => setPlanModalOpen(true)}>
              업그레이드
            </a>
          </>
        )
      },
    },

    // 공동인증서 만료/폐기안내
    NEED_CERTIFICATE_REGISTERED_WHEN_EXPIRED: {
      variant: "danger",
      closeBtn: false,
      children() {
        return (
          <>
            만료된 인증서가 있습니다. 인증서 관리로 이동하여 삭제 후 새로 등록해
            주세요.
            {authorizedFeatures.includes("CERTIFICATE_MANAGEMENT") && (
              <a className={linkClass} onClick={() => redirectToCertification()}>
                재등록하기
              </a>
            )}
          </>
        )
      },
    },
  }

  const alert = Banners[alertKey]

  if (!alert) return null

  const linkClass = `${
    COLORS[alert.variant].linkColor
  } cursor-pointer break-keep ml-2 max-sm:hidden font-semibold`

  const handleClose = () => {
    setBypassKey(alertKey)
    setTimeout(() => {
      window.location.reload()
    }, 10)
  }

  return (
    <>
      <Banner
        variant={alert.variant}
        isDismissable={alert.closeBtn}
        onClick={() => handleClose()}
      >
        {alert.children()}
      </Banner>

      <PlanModal isOpen={planModalOpen} onOpenChange={setPlanModalOpen} />
    </>
  )
}
