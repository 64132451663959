import { useState } from "react"

export const usePopover = (id: string) => {
  const [baseAnchorElement, setBaseAnchorElement] = useState<HTMLElement | null>(null)
  const open = Boolean(baseAnchorElement)
  const popoverId = open ? id : undefined
  const anchorElWidth = baseAnchorElement?.getBoundingClientRect().width
  return {
    id: popoverId,
    open,
    baseAnchorElement,
    setBaseAnchorElement,
    baseAnchorElementWidth: anchorElWidth,
  }
}
