import { useEffect } from "react"
import mixpanel, { Persistence } from "mixpanel-browser"

const MixpanelProvider = ({ token }: { token?: string }) => {
  useEffect(() => {
    if (!token) {
      console.error("Mixpanel token is not provided")
      return
    }
    const config = {
      debug: false,
      track_pageview: false,
      cross_subdomain_cookie: true,
      persistence: "localStorage" as Persistence,
    }
    if (process.env.NODE_ENV === "development") {
      config.debug = true
    }
    mixpanel.init(token, config)
  }, [token])
  return <></>
}

export default MixpanelProvider
