import Icon1 from "@/assets/icons/alert-icon1.svg"
import Icon2 from "@/assets/icons/alert-triangle.svg"
import Icon3 from "@/assets/icons/alert-circle-c.svg"
import CloseIcon from "@/assets/icons/x-close2.svg"
import { COLORS } from "./constants"

type Props = {
  variant: string
  children: React.ReactNode
  isDismissable?: boolean
  onClick?: () => void
}

export default function Banner({ variant, children, isDismissable, onClick }: Props) {
  const bgColor = COLORS[variant].bgColor

  return (
    <div
      className={`${bgColor} sticky left-0 top-[48px] z-[11] flex w-full px-2 py-4 text-secondary md:px-10`}
    >
      <div className="mr-2">
        {variant === "warning" && <Icon1 />}
        {variant === "danger" && <Icon2 />}
        {variant === "info" && <Icon3 />}
      </div>
      <div className="mr-3 inline-block">{children}</div>
      {isDismissable && (
        <div
          className="ml-auto cursor-pointer p-1"
          onClick={() => {
            if (onClick) onClick()
          }}
        >
          <CloseIcon />
        </div>
      )}
    </div>
  )
}
