"use client"
import { useLoadingOverlayStore } from "@/store/loadingOverlayStore"
import { useState, useEffect } from "react"
import Ellipsis from "./Ellipsis"

export default function LoadingOverlay() {
  const isLoading = useLoadingOverlayStore(state => state.isLoading)
  const [showMask, setShowMask] = useState(false)

  useEffect(() => {
    let timer

    if (isLoading) {
      timer = setTimeout(() => {
        setShowMask(true)
      }, 400)
    } else {
      setShowMask(false)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [isLoading])

  if (!isLoading) return null

  return (
    <div
      className={
        "fixed left-0 top-0 z-[1400] flex h-full w-full items-center justify-center"
      }
      style={{
        opacity: showMask ? 1 : 0,
      }}
    >
      <div className="absolute h-full w-full bg-white opacity-80" />
      <Ellipsis />
    </div>
  )
}
