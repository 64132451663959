"use client"
import { useState, useEffect } from "react"
import { usePathname } from "next/navigation"
import GlobalLayout from "./GlobalLayout"
import Snackbar from "../Snackbar/Snackbar"
import { BoltaEvent } from "@/utils/mixpanels"

export default function RootLayout({ children }) {
  const [mounted, setMounted] = useState(false)
  const pathname = usePathname()
  const isAuthPage =
    pathname === "/login" ||
    pathname === "/login-success" ||
    pathname === "/find-password" ||
    pathname === "/invitations" ||
    pathname.startsWith("/join") ||
    pathname.startsWith("/billing/register-card") ||
    pathname.startsWith("/print")
  const isIssuanceResultPage = pathname === "/issuance-result"
  const isRequestFormPage =
    pathname.startsWith("/request/") || pathname === "/request-payment/requests/new"
  const banPage = pathname === "/ban"

  useEffect(() => {
    setTimeout(() => {
      setMounted(true)
    }, 10)
  }, [])

  useEffect(() => {
    BoltaEvent.trackPageView()
  }, [pathname])

  if (!mounted) {
    return <div></div>
  }

  return (
    <>
      {isAuthPage || isIssuanceResultPage || isRequestFormPage || banPage ? (
        <div className="h-[100dvh] overflow-auto">
          <Snackbar />
          {children}
        </div>
      ) : (
        <GlobalLayout>{children}</GlobalLayout>
      )}
    </>
  )
}
