"use client"
import TagManager from "react-gtm-module"
import { useEffect } from "react"

const GoogleTagManager = ({ id }: { id: string }) => {
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== "production") return
    TagManager.initialize({ gtmId: id })
  }, [id])
  return <></>
}

export default GoogleTagManager
