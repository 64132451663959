import { instance } from "@/app/api/api"
import { workspaceId } from "@/app/api/config"

export const putWorkspace = async data => {
  const url = `/api/v2/workspaces/${workspaceId}`

  try {
    await instance.put(url, data)
    return true
  } catch (error) {
    return null
  }
}

export const fetchCertificationRegistrationUrl = async () => {
  const url = `/api/v1/workspaces/${workspaceId}/certifications:getRegistrationUrl`
  const {
    data: { body },
  } = await instance.get(url)

  return body
}

export const postWorkspace = async data => {
  const url = `/api/v1/workspaces`

  const {
    data: { body },
  } = await instance.post(url, data)

  return body
}

export const getAlertBanner = async (bypassKeys: string[]) => {
  const url = `/api/v1/workspaces/${workspaceId}:alertBanner`

  const headers = {
    ...instance.defaults.headers.common,
    "X-Bypass-Banners": bypassKeys.length ? bypassKeys : undefined,
  }

  const {
    data: { body },
  } = await instance.get(url, {
    headers,
  })

  return body
}
