"use client"
import { NextUIProvider } from "@nextui-org/react"
import { useTailwindBreakpoint } from "@/hooks/useTailwindBreakpoint"
import { useBreakpointStore } from "@/store/layoutStore"
import { useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import {
  ChannelTalk,
  GoogleTagManager,
  MicrosoftClarity,
  MixpanelProvider,
} from "@/providers"

const queryClient = new QueryClient()

export function Provider({ children }: { children: React.ReactNode }) {
  const breakpoint = useTailwindBreakpoint()
  const setBreakpoint = useBreakpointStore(state => state.setBreakpoint)

  useEffect(() => {
    setBreakpoint(breakpoint)
  }, [breakpoint, setBreakpoint])

  return (
    <>
      <GoogleTagManager id="GTM-59ZRC8ZJ" />
      <MicrosoftClarity id="jl1n8d8te5" />
      <ChannelTalk />
      <MixpanelProvider token={process.env.NEXT_PUBLIC_MIXPANEL_TOKEN} />
      <QueryClientProvider client={queryClient}>
        <NextUIProvider>{children}</NextUIProvider>
      </QueryClientProvider>
    </>
  )
}
