import { workspaceId } from "@/app/api/config"

export const redirectToCertification = () => {
  const url = `${process.env.NEXT_PUBLIC_API_URL}/MagicLine4Web/ML4Web/certificate.html`
  const info = btoa(
    JSON.stringify({
      workspaceId,
      redirectUrl: "/certification-result",
    }),
  )
  window.location.href = `${url}?info=${info}`
}
