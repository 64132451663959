import { usePathname } from "next/navigation"
import { ChevronDownIcon } from "@heroicons/react/24/solid"
import { useLayoutStore } from "@/store/layoutStore"
import React from "react"

export interface Group {
  title: string
  icon: React.ElementType
  activeIcon: React.ElementType
  children: React.ReactNode
  href: string
}

export default function AGroup({ title, icon, activeIcon, href, children }: Group) {
  const pathname = usePathname()
  const Icon = icon
  const ActiveIcon = activeIcon
  const openGroup = useLayoutStore(state => state.openGroup)
  const setOpenGroup = useLayoutStore(state => state.setOpenGroup)
  // const isActive = pathname.includes(href)
  const isActive = pathname.split("/")[1] === href

  const addOpenGroup = (name: string) => {
    if (openGroup.indexOf(name) === -1) {
      setOpenGroup([...openGroup, name])
    } else {
      setOpenGroup(openGroup.filter(item => item !== name))
    }
  }

  const isGroupOpen = React.useMemo(
    () => openGroup.indexOf(title) !== -1,
    [openGroup, title],
  )

  return (
    <>
      <div
        className={`transition-background-color flex h-12 cursor-pointer select-none items-center gap-2 rounded-lg px-3 font-medium ${
          isActive && "bg-primary-lighten text-primary"
        }`}
        onClick={() => addOpenGroup(title)}
      >
        {isActive ? <ActiveIcon /> : <Icon />}
        <div>{title}</div>
        <ChevronDownIcon
          className={`ml-auto h-[18px] w-[18px] transition ${
            isGroupOpen ? "-rotate-180 transform" : ""
          }`}
        />
      </div>
      <div
        className="overflow-hidden transition-all"
        style={{
          maxHeight: isGroupOpen ? 1000 : 0,
        }}
      >
        {children}
      </div>
    </>
  )
}
