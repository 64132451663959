import { useProfileStore } from "@/store/profileStore"
import { useRouter } from "next/navigation"
import { WorkspaceInfo } from "@/types/profile"

import MenuItem from "./MenuItem"
import Popover from "@mui/material/Popover"
import CheckIcon from "@/assets/icons/check2.svg"
import PlusIcon from "@/assets/icons/plus2.svg"

type Props = {
  anchorEl: HTMLElement | null
  onClose: () => void
}

export default function WorkspaceSwitch({ anchorEl, onClose }: Props) {
  const profile = useProfileStore(state => state.profile)
  const workspaces = useProfileStore(state => state.workspaces)
  const setProfile = useProfileStore(state => state.setProfile)
  const router = useRouter()
  const open = Boolean(anchorEl)
  const id = open ? "workspace-switch-popover" : undefined

  const handleSwitchWorkspace = (workspaceInfo: WorkspaceInfo) => {
    if (!profile) return

    setProfile({
      ...profile,
      workspace: workspaceInfo.workspace,
    })

    setTimeout(() => {
      window.location.href = "/login-success"
    }, 100)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      className="bolta-popover"
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 26,
        horizontal: 280,
      }}
    >
      <div className="min-w-[272px] rounded-lg border bg-white p-2">
        <div className="max-h-[288px] overflow-auto">
          {workspaces.map((workspace, index) => {
            const isCurrent = workspace.workspace.id === profile?.workspace.id

            return (
              <MenuItem
                key={index}
                onClick={() => {
                  if (isCurrent) return
                  handleSwitchWorkspace(workspace)
                }}
              >
                {workspace.workspace.organizationName}
                {isCurrent && <CheckIcon className="ml-auto" />}
              </MenuItem>
            )
          })}
        </div>
        <hr />
        <div
          className="-mb-2 flex cursor-pointer items-center justify-center px-4 py-3 text-xs text-[#324765]"
          onClick={() => router.push("/join/step1?isCreate=true")}
        >
          <PlusIcon className="mr-1" />
          신규 워크스페이스 생성
        </div>
      </div>
    </Popover>
  )
}
