import { create } from "zustand"
import { persist } from "zustand/middleware"

interface AlertState {
  bypassKeys: string[]
  setBypassKey: (key: string) => void
}

export const useAlertStore = create<AlertState>()(
  persist(
    (set, get) => ({
      bypassKeys: [],
      setBypassKey: key => {
        if (!get().bypassKeys.includes(key)) {
          set(state => ({
            bypassKeys: [...state.bypassKeys, key],
          }))
        }
      },
    }),
    {
      name: "alerts", // persist key
    },
  ),
)
